<template>
  <v-row class="match-height">
    <v-col cols="12">
      <app-card-code :title="$t('btn.import')">
        <v-card-text>
          <v-form class="multi-col-validation">
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-card>
                  <v-card-text>
                    <p class="text--primary text-base">
                      <v-file-input
                        v-model="jsonFile"
                        type="file"
                        accept="application/JSON"
                        show-size
                        outlined
                        dense
                        label="JSON"
                        hide-details
                        @change="setJson"
                      ></v-file-input>
                    </p>
                  </v-card-text>
                  <v-card-actions class="primary pa-0">
                    <v-btn
                      color="primary"
                      block
                      dark
                      large
                      :loading="loading"
                      @click="save()"
                    >
                      <span>{{ $t('btn.send') }}</span>
                      <v-icon>{{ icons.mdiUpdate }}</v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
              <v-col
                v-if="jsonData != null"
                cols="12"
                md="6"
              >
                <json-viewer
                  :value="jsonData"
                  :sort="true"
                  :boxed="true"
                ></json-viewer>
              </v-col>
            </v-row>

            <!--<v-row>
              <v-col
                cols="12"
                md="6"
              >
                <LeafletExample></LeafletExample>
              </v-col>
            </v-row>-->
          </v-form>
        </v-card-text>
      </app-card-code>
    </v-col>
  </v-row>
</template>

<script>
// eslint-disable-next-line import/no-unresolved
import AppCardCode from '@/@core/components/app-card-code/AppCardCode.vue'
import {
  mdiMagnify, mdiDeleteOutline, mdiSquareEditOutline, mdiPlus, mdiArrowLeft, mdiUpdate,
} from '@mdi/js'

// eslint-disable-next-line import/no-unresolved
// import LeafletExample from '@/views/leaflet/Example.vue'

export default {
  components: {
    AppCardCode,

    // LeafletExample,
  },
  data() {
    return {
      isLoading: true,
      jsonFile: null,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
      },
      loading: false,
      jsonData: null,
    }
  },

  methods: {
    setJson(e) {
      if (e) {
        this.readFile(e)
      } else {
        this.jsonData = null
      }
    },
    readFile(file) {
      const reader = new FileReader()
      reader.onload = e => {
        // this.jsonData = e.target.result
        // eslint-disable-next-line no-unused-vars
        this.jsonData = JSON.parse(e.target.result)
      }
      reader.readAsText(file)
    },
    save() {
      if (this.jsonFile) {
        this.loading = true
        const formData = new FormData()
        formData.append('json', this.jsonFile)
        this.axios
          .post('import-json/airport', formData, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(response => {
            // console.log(response.data)
            if (response.data.success === false) {
              // this.$toast.error(this.$t('msg.noAutorice'))
            } else {
              this.$toast.success(this.$t('msg.fileUpload'))
            }
          })
          .catch(error => console.log(error))
          // eslint-disable-next-line no-return-assign
          .finally(() => this.reset())
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },
    reset() {
      this.loading = false
      this.jsonFile = null
      this.jsonData = null
    },
  },
}
</script>
